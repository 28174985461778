import React from "react";
import { useEffect } from "react";
import "../styles/aboutme.css";
import profile from "./../images/profile.jpeg";

function AboutMe() {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row">
          <h1 className="section__title">
            About <span className="purple"> Me </span>
          </h1>
          <div className="about__wrapper ">
            <p className="about__para ">
              I’m a Developer in <b className="purple">Lebanon</b> who's
              passionate about crafting for the web, from front-end to back-end.
              A problem solver, well-organised person and have high attention to
              detail. Interested in the entire development spectrum and
              enthusiastic about working on ambitious projects.
            </p>
            <figure className="">
              <img
                className="about__img "
                src={profile}
                alt="profile"
                draggable="false"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
